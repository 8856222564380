// https://v3.vuex.vuejs.org/zh/guide/modules.html#module vuex3.x 模块化状态官方文档
import { MenuConfig } from "@/constants/MenuConfig";

function isMenuItemVisible(config, userInfoRole, state) {
  if (config.hidden) return false;
  return (
    !state.unselected.includes(config.index) &&
    (config.userRoles.includes(userInfoRole) ||
      config.userRoles.includes("all"))
  );
}
function isSubMenuVisible(group, userInfoRole, state) {
  for (let config of group.menus) {
    if (
      !state.unselected.includes(config.index) &&
      isMenuItemVisible(config, userInfoRole, state) === true
    )
      return true;
  }
  return false;
}

const state = {
  // 系统配置里 用户选择隐藏的菜单栏，目前只读
  unselected: JSON.parse(localStorage.getItem("Unselected")) || [],
  // 前端配置路由，目前只读
  MenuConfig,
  currentRouterPath: "",
};
const mutations = {
  changePath(state, value) {
    if (typeof value === "string") {
      state.currentRouterPath = value;
    }
  },
};
const actions = {};
const getters = {
  menuConfigView: (state, _getters, rootState) => {
    return state.MenuConfig.filter((group) =>
      isSubMenuVisible(group, rootState.userInfoRole, state)
    ).map((group) => ({
      ...group,
      menus: group.menus.filter((config) =>
        isMenuItemVisible(config, rootState.userInfoRole, state)
      ),
    }));
  },
};

const menuStore = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default menuStore;
