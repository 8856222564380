import Vue from 'vue';
import Vuex from 'vuex';
import {
    getEmptyStatementOrderCount,
    getLastRoleIsVendorStatementNumber,
    getPendingBillsCount,
    getPendingUnfinishedCount,
} from "@/api/order";
import menuStore from './menuStore'

Vue.use(Vuex);

const modules = {menuStore}

const state = {
    orderId: "",
    currencyList: [],
    juniorList: [],
    userList: [],
    docTemplates: [],
    totalDocTemplates: '',
    optionList: [],
    selectColumns: [],
    userInfoRole: null,
    userInfo: null,
    isCollapse: false,
    canBack: true,
    isOkLoading: false,
    iata: [],
    port: [],
    worldRoute: {},
    vendorStatementCount: 0,
    emptyStatementOrderCount: 0,
    pendingBillsCount: 0,
    pendingUnfinishedCount: 0,
    ipData: null,
    logs: [],
    reLogin: false,
};
const actions = {
    asVendorStatementCount({ commit }) {
        console.log('----Vuex----- count请求---');
        getLastRoleIsVendorStatementNumber().then(res => {
            commit('saveVendorStatementCount', res.data)
        })
    },
    asEmptyStatementOrderCount({ commit }) {
        getEmptyStatementOrderCount().then(res => {
            commit('saveEmptyStatementOrderCount', res.data)
        })
    },
    async asPendingBillsCount({ commit }) {
        try {
            let res = await getPendingBillsCount();
            console.log("待催款账单数量",res)
            
            commit('savePendingBillsCount', res.data);
        } catch (err) {
            console.log(err);
        }
    },
    // 应收款账单请求数据
    async asPendingUnfinishedCount({ commit }) {
        try {
            let res = await getPendingUnfinishedCount();
            console.log("应收款账单数量-----",res)
            commit('savePendingUnfinishedCount', res.data);
        } catch (err) {
            console.log(err);
        }
    },
    addErrorLog({ commit }, log) {
        commit('ADD_ERROR_LOG', log)
    },
    clearErrorLog({ commit }) {
        commit('CLEAR_ERROR_LOG')
    }
}
const mutations = {
    showReLogin: (state) => {
        state.reLogin = true;
    },
    hideReLogin: (state) => {
        state.reLogin = false;
    },
    ADD_ERROR_LOG: (state, log) => {
        state.logs.push(log)
    },
    CLEAR_ERROR_LOG: (state) => {
        state.logs.splice(0)
    },
    setIpData(state, e) {
        state.ipData = e;
    },
    // 待催款账单数量统计保存
    savePendingBillsCount(state, e) {
        state.pendingBillsCount = e;
    },
    // 应收款账单数量统计保存
    savePendingUnfinishedCount(state, e) {
        state.pendingUnfinishedCount = e;
    },
    saveEmptyStatementOrderCount(state, e) {
        state.emptyStatementOrderCount = e
    },
    saveVendorStatementCount(state, e) {
        state.vendorStatementCount = e
    },
    setCurrencyList(state, e) {
        console.log(e)
        state.currencyList = e
    },
    setOrderId(state, e) {
        state.orderId = e
    },
    sageOptionList(state, e) {
        state.optionList = e
    },
    savelistDocGet(state, e) {
        state.docTemplates = e.templates
        state.totalDocTemplates = e.totalDocTemplates
    },
    saveUserList(state, e) {
        state.userList = e
    },
    savejuniorList(state, e) {
        state.juniorList = e
    },
    setUserInfo(state, e) {
        state.userInfo = e
    },
    setWorldRoute(state, e) {
        state.worldRoute = e
    },
    setIata(state, e) {
        state.iata = e
    },
    setPort(state, e) {
        state.port = e
    },
    setisOkLoadingTrue(state) {
        state.isOkLoading = true
    },
    setisOkLoadingFalse(state) {
        state.isOkLoading = false
    },
    // 修改选中的数据
    editSelectColumns(state, newValue) {
        state.selectColumns = newValue;
    },
    setUserInfoRole(state, log) {
        state.userInfoRole = log
    },
    setIsCollapse(state, log) {
        state.isCollapse = log.collapse
    },
    allowBack: state => state.canBack = true,
    notAllowBack: state => {
        state.canBack = false
        history.pushState(null, null, location.href)
    }
};
const getters = {
    errorLogs: state => state.logs,
}
const store = new Vuex.Store({
    state,
    mutations,
    actions,
    getters,
    modules
});

export default store;
